<template>
  <div class="decorated">
    <HeaderNavigation title="TROUVER UN POINT DE RECHARGE" />
    <b-container id="search" class="main-container">
      <b-row>
        <b-col>
          <b-form ref="form" novalidate>
            <b-form-group label="Où allez vous ?">
              <b-form-row>
                <b-col cols="12">
                  <InputAddress v-model="searchDestination" class="address" />
                  <!-- <b-input-group>
                    <template v-if="false" v-slot:append>
                      <fa-icon icon="crosshairs" @click="onUserLocation" />
                    </template>
                  </b-input-group> -->
                </b-col>
              </b-form-row>
            </b-form-group>
            <b-form-group label="Quand partez-vous ?">
              <b-button-group class="switch">
                <b-button
                  :variant="isNow ? 'dark' : 'light'"
                  @click.prevent="setNow"
                  >MAINTENANT</b-button
                >
                <b-button
                  :variant="isToday ? 'dark' : 'light'"
                  @click.prevent="setToday"
                  >AUJOURD'HUI</b-button
                >
              </b-button-group>
              <div class="days">
                <b-button
                  :variant="isTomorrow ? 'dark' : 'light'"
                  @click.prevent="setTomorrow"
                  >DEMAIN</b-button
                >
                <b-button
                  :variant="isDayAfterTomorrow ? 'dark' : 'light'"
                  @click.prevent="setDayAfterTomorrow"
                  >{{ dayAfterTomorrow }}</b-button
                >
                <b-button
                  :variant="isDayAfterDayAfterTomorrow ? 'dark' : 'light'"
                  @click.prevent="setDayAfterDayAfterTomorrow"
                  >{{ dayAfterDayAfterTomorrow }}</b-button
                >
              </div>
              <b-form-radio-group
                v-show="false"
                button-variant="dark"
                class="when"
                name="rbtnWhen"
                buttons
                v-model="search.when"
                :options="whenOptions"
              />
            </b-form-group>
            <b-form-group label="A quelle heure ?" class="time-picker">
              <b-form-input
                type="time"
                v-model="search.time"
                :min="minTime"
                required
                :disabled="isNow"
              />
            </b-form-group>
            <b-form-group label="Quel temps de recharge ?" class="duration">
              <b-form-slider
                v-model="search.duration"
                :min="minDuration"
                :max="maxDuration"
                :step="stepDuration"
                :formatter="formatDuration"
                tooltip="always"
                tooltip-position="bottom"
                handle="square"
              />
            </b-form-group>
            <footer v-if="canDoSearch" class="text-right">
              <b-button
                :to="routes.SEARCH_RESULTS"
                class="go"
                router-tag="button"
                :disabled="!isValid"
                >GO!</b-button
              >
            </footer>
            <b-row v-else class="invalid-parameter no-gutters">
              <b-col cols="9">
                <p>
                  Vous ne pouvez actuellement pas utiliser notre service car un
                  de vos paramètre n’est pas valide.
                </p>
                <router-link :to="routes.ACCOUNT"
                  >Accéder à mes paramètres</router-link
                >
              </b-col>
              <b-col
                cols="3"
                class="d-flex align-items-end justify-content-end"
              >
                <div class="bang">!</div>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { SearchWhen } from '@/assets/js/Constants.js'
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import InputAddress from '@/components/Search/InputAddress.vue'
import { Routes } from '@/router.js'
import { formatDuration } from '@/utils/search-utils.js'
import { mapGetters } from 'vuex'

const moment = require('moment')

export default {
  name: 'search',
  components: { HeaderNavigation, InputAddress },
  data() {
    return {
      search: {
        destination: {
          address: '',
          position: { lon: 0, lat: 0 }
        },
        when: SearchWhen.Now,
        time: this.getCurrentTime(),
        duration: 120 // 2 hours
      },
      isValid: false,
      currentTime: this.getCurrentTime(),
      interval: null,
      minDuration: 60, // 1 hour
      maxDuration: 480, // 8 hours
      stepDuration: 60, // 1 hour
      routes: Routes
    }
  },
  computed: {
    ...mapGetters(['canDoSearch']),
    searchDestination: {
      get() {
        return {
          address: { freeformAddress: this.search.destination.address },
          position: { ...this.search.destination.position }
        }
      },
      set(value) {
        this.search.destination.address = value.address.freeformAddress
        this.search.destination.position = { ...value.position }
      }
    },
    isNow() {
      return this.search.when == SearchWhen.Now
    },
    isToday() {
      return this.search.when == SearchWhen.Today
    },
    isTomorrow() {
      return this.search.when == SearchWhen.Tomorrow
    },
    isDayAfterTomorrow() {
      return this.search.when == SearchWhen.DayAfterTomorrow
    },
    isDayAfterDayAfterTomorrow() {
      return this.search.when == SearchWhen.DayAfterDayAfterTomorrow
    },
    dayAfterTomorrow() {
      const date = new Date()
      date.setDate(date.getDate() + 2)

      return date
        .toLocaleDateString('fr-FR', {
          weekday: 'long',
          day: 'numeric'
        })
        .toUpperCase()
    },
    dayAfterDayAfterTomorrow() {
      const date = new Date()
      date.setDate(date.getDate() + 3)

      return date
        .toLocaleDateString('fr-FR', {
          weekday: 'long',
          day: 'numeric'
        })
        .toUpperCase()
    },
    whenOptions() {
      return [
        { text: 'MAINTENANT', value: SearchWhen.Now },
        { text: "AUJOURD'HUI", value: SearchWhen.Today },
        { text: 'DEMAIN', value: SearchWhen.Tomorrow },
        { text: this.dayAfterTomorrow, value: SearchWhen.DayAfterTomorrow },
        {
          text: this.dayAfterDayAfterTomorrow,
          value: SearchWhen.DayAfterDayAfterTomorrow
        }
      ]
    },
    minTime() {
      if (this.isNow || this.isToday) {
        return this.currentTime
      } else {
        return '00:00'
      }
    },
    isTimeValid() {
      return (
        moment.duration(this.search.time).asMinutes() >=
        moment.duration(this.minTime).asMinutes()
      )
    }
  },
  watch: {
    search: {
      handler: function() {
        this.validate()
      },
      deep: true
    },
    'search.when': function() {
      if (!this.isTimeValid) {
        this.search.time = this.currentTime
      }
    }
  },
  created() {
    this.interval = setInterval(
      () => (this.currentTime = this.getCurrentTime()),
      1000
    )
  },
  beforeDesroy() {
    clearInterval(this.interval)
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == Routes.SEARCH_RESULTS.name) {
      next(vm => {
        const { destination, when, time, duration } = vm.$store.state.search
        vm.search.destination = { ...destination }
        vm.search.when = when
        vm.search.time = time
        vm.search.duration = duration

        vm.$nextTick(() => vm.validate())
      })
    } else {
      next()
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (to.name == Routes.SEARCH_RESULTS.name) {
      if (!this.isTimeValid) {
        this.validate()
        next(false)
      } else if (!this.isValid) {
        next(false)
      } else {
        await this.$store
          .dispatch('setSearch', this.search)
          .then(() => next())
          .catch(() => next(false))
      }
    } else {
      next()
    }
  },
  methods: {
    onUserLocation() {
      console.log('User Location')
    },
    getCurrentTime() {
      const date = new Date()

      return date.toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit'
      })
    },
    setNow() {
      this.search.when = SearchWhen.Now
    },
    setToday() {
      this.search.when = SearchWhen.Today
    },
    setTomorrow() {
      this.search.when = SearchWhen.Tomorrow
    },
    setDayAfterTomorrow() {
      this.search.when = SearchWhen.DayAfterTomorrow
    },
    setDayAfterDayAfterTomorrow() {
      this.search.when = SearchWhen.DayAfterDayAfterTomorrow
    },
    formatDuration(value) {
      return formatDuration(value)
    },
    validate() {
      this.isValid = this.$refs.form && this.$refs.form.checkValidity()
    }
  }
}
</script>
<style lang="scss">
@import '@/styles.scss';

#search {
  form {
    position: relative;

    .form-group {
      margin-top: 34px;

      &:last-of-type {
        margin-bottom: 50px;
      }
      .col-form-label {
        font-weight: initial;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0;
        color: $dark;
      }
    }
    input {
      border: none;
      border-radius: 10px;
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);
    }
    .address {
      input {
        height: 48px;
        background: #fff url('../assets/img/icon-search.png') no-repeat 15px;
        padding-left: 40px;
        font-weight: initial;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0;
        color: $dark;
      }
    }
    .switch {
      background-color: #fff;
      border-radius: 10px;
      width: 100%;
      height: 48px;
    }
    .switch,
    .days {
      button {
        font-weight: $font-weight-bold;
        font-size: 16px;
        letter-spacing: 0;

        &.btn-dark {
          color: #fff;
        }

        &.btn-light {
          background-color: #fff;
          box-shadow: none;
          color: #9a9a9a;
        }

        &.btn-dark:hover {
          background-color: $dark;
        }
      }
    }
    .days {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
      .btn {
        width: 30%;
        height: 35px;
        font-size: 12px;
        line-height: 35px;
      }
    }
    .time-picker {
      div {
        text-align: center;
      }
      input {
        width: 98px;
        height: 48px;
        margin: auto;
        font-weight: $font-weight-bold;
        font-size: 16px;
        line-height: 48px;
        color: $dark;
        text-align: center;
      }
    }
    .duration {
      .d-inline-block {
        width: 100%;

        .slider {
          margin-top: 15px;

          &.slider-horizontal {
            width: 100%;
          }

          .slider-selection,
          .slider-track-high {
            background-image: none;
            height: 7px;
          }

          .slider-selection {
            background-color: $dark;
          }

          .slider-track-high {
            background-color: #f2f2f2;
          }

          .tooltip.in {
            opacity: 1;
            margin-left: 4px;

            .tooltip-inner {
              width: 29px;
              padding: 0;
              padding-top: 15px;
              background-color: transparent;
              font-family: $font-family;
              font-weight: $font-weight-bold;
              font-size: 9px;
              line-height: 11px;
              letter-spacing: 0;
              color: $dark;
            }
          }

          .slider-handle {
            border-radius: 10px;
            width: 29px;
            height: 48px;
            top: -75%;
            background-color: #fff;
            background-image: none;
            box-shadow: rgba(0, 0, 0, 0.22) 0px 3px 6px;

            &:before {
              content: '';
              display: block;
              margin: 9.5px 8px 9.5px 9px;
              width: 12px;
              height: 29px;
              background: repeating-linear-gradient(
                to right,
                #e5e5e5,
                #e5e5e5 1px,
                transparent 1px,
                transparent 5px
              );
            }

            &:active {
              background-color: $button-active;

              &:before {
                background: repeating-linear-gradient(
                  to right,
                  #fff,
                  #fff 1px,
                  transparent 1px,
                  transparent 5px
                );
              }
            }
          }
        }
      }
    }
    .btn.go {
      width: 59px;
      height: 59px;
      border-radius: 30px;
      border: none;
      background: rgb(19, 236, 220);
      background: linear-gradient(
        296deg,
        rgba(19, 236, 220, 1) 0%,
        rgba(50, 146, 229, 1) 100%
      );
      box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.47);
      -webkit-box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.47);
      -moz-box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.47);
      font-weight: $font-weight-bold;
      font-size: 16px;
      line-height: 59px;
      color: #ffffff;
    }
    .invalid-parameter {
      padding: 10px 20px 20px 20px;
      background-color: $invalid-data-color;
      font-weight: $font-weight-regular;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.7px;
      color: $invalid-data-color-invert;

      a {
        font-weight: $font-weight-bold;
        color: $invalid-data-color-invert;
        text-transform: uppercase;
        text-decoration: none;
      }

      .bang {
        border-radius: 50%;
        width: 59px;
        height: 59px;
        background-color: $invalid-data-color-invert;
        color: $invalid-data-color;
        font-weight: $font-weight-bold;
        text-align: center;
        line-height: 59px;
      }
    }
  }
}
</style>
